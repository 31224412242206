import React from "react"
import styled from "styled-components"

import Container from "../Container"
import StepBubble from "../Bubble"

const HowItWorksSection = styled.section`
  background-color: #fff;
  padding: 4.5454em 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 5fr 7fr;
  }
`

const StyledH2 = styled.h2`
  font-size: 1.8181em;
  line-height: 1.35;
`

const StepContainer = styled.div`
  display: grid;
  grid-gap: 35px;
  grid-template-columns: 1fr 11fr;
  margin-bottom: 2.2727rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const StepTitle = styled.h3`
  font-family: "Quando", serif;
  font-size: 1.1818em;
  margin-bottom: 0.909rem;
`

const StepText = styled.p`
  margin: 0;
`

const HowItWorks = () => {
  return (
    <HowItWorksSection id="hogyan-mukodik">
      <Container>
        <Grid>
          <StyledH2 className="wow fadeInLeft">Hogyan működik?</StyledH2>
          <div>
            <StepContainer className="wow fadeInRight">
              <StepBubble>1</StepBubble>
              <div>
                <StepTitle>Támogatod a Hősöket.</StepTitle>
                <StepText>
                  Az értünk vívott küzdelem elismeréseként a helyi éttermek
                  étellel támogatják hőseinket. Céljuk minden esetben az, hogy
                  erőt, bátorítás és kitartást adjanak azoknak a hősöknek, akik
                  értünk dolgoznak.
                </StepText>
              </div>
            </StepContainer>
            <StepContainer className="wow fadeInRight" data-wow-delay=".2s">
              <StepBubble>2</StepBubble>
              <div>
                <StepTitle>A Hősök rendelnek.</StepTitle>
                <StepText>
                  Hőseink a rendszeren keresztül látják, milyen felajánlás
                  érkezett a kezdeményezésünkhöz csatlakozott éttermekből, majd
                  ezután megrendelhetik azt az adott mennyiséget, amire
                  szükségük van.
                </StepText>
              </div>
            </StepContainer>
            <StepContainer className="wow fadeInRight" data-wow-delay=".4s">
              <StepBubble>3</StepBubble>
              <div>
                <StepTitle>Szállítanak a Hősöknek. </StepTitle>
                <StepText>
                  Partnereink teljesítik a Hősök kéréseit és az igényelt
                  mennyiséget leszállítják a megadott időben a megadott
                  intézménybe.
                </StepText>
              </div>
            </StepContainer>
          </div>
        </Grid>
      </Container>
    </HowItWorksSection>
  )
}

export default HowItWorks
