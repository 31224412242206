
const BARION_LOCAL_STORAGE_KEY = "barion";

export function getBarionEnabled(queryParam) {
  if (queryParam) {
    localStorage.setItem(BARION_LOCAL_STORAGE_KEY, queryParam);
  }

  return queryParam || JSON.parse(localStorage.getItem(BARION_LOCAL_STORAGE_KEY));
}
