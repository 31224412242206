import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { usePlaceholder } from "../hooks/placeholder"

const Card = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 4.5454rem;
  }
`

const CardImage = styled.div`
  width: 135px;
  height: 135px;
  margin-bottom: 1.45rem;
  background-image: ${props => props.src ? 'url("' + props.src + '")' : ''};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media all and (min-width: 768px) {
    width: 150px;
    height: 150px;
  }
`

const StyledImg = styled(Img)`
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 1.45rem;
`

const CardTitle = styled.h3`
  font-size: 1.1818em;
  font-family: "Quando", serif;
  margin-bottom: 0.909rem;
`

const CardText = styled.p`
  margin: 0;
`

const Cards = ({ elements = [] }) => {
  const { placeholder } = usePlaceholder()

  return elements.map(({ logo, name, description }, idx) => (
    <Card key={idx} className="wow fadeIn" data-wow-delay={`${idx * 0.1}s`}>
      {logo ? <CardImage src={logo} /> : <StyledImg fluid={placeholder} />}
      <CardTitle>{name}</CardTitle>
      <CardText>{description}</CardText>
    </Card>
  ))
}

export default Cards
