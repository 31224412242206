import { graphql } from 'gatsby'
import qs from 'qs'
import React, { useEffect, useState } from "react"
import '../../node_modules/animate.css'
import Layout from "../components/layout"
// import Achievements from "../components/sections/Achievements"
import Contact from "../components/sections/Contact"
import Donate from "../components/sections/Donate"
import Goals from "../components/sections/Goals"
import HealthCare from "../components/sections/HealthCare"
import Hero from "../components/sections/Hero"
import HowItWorks from "../components/sections/HowItWorks"
import Partners from "../components/sections/Partners"
import Press from "../components/sections/Press"
import SEO from "../components/seo"
import { getBarionEnabled } from '../services/enable-barion'

const IndexPage = ({ data, location: { search } }) => {
  const [shopEnabled, setShopEnabled] = useState(false);

  useEffect(() => {
    const { barion } = qs.parse(search, { ignoreQueryPrefix: true })
    setShopEnabled(getBarionEnabled(barion));
  })

  return (<Layout>
    <SEO title="Home" />
    <Hero heroImage={data.heroImage.childImageSharp.fluid} />
    <Goals />
    <HowItWorks />
    {/* <Achievements image={data.support.childImageSharp.fluid} bg={data.bg.childImageSharp.fixed.src}/> */}
    {shopEnabled && <Donate
      onePortion={data.onePortion.childImageSharp.fluid}
      fivePortion={data.fivePortion.childImageSharp.fluid}
      tenPortion={data.tenPortion.childImageSharp.fluid}
      xPortion={data.xPortion.childImageSharp.fluid}
    />}
    <Contact />
    <HealthCare />
    <Partners />
    <Press />
  </Layout>)
}

export default IndexPage

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "Etesdahosoket.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    support: file(relativePath: { eq: "tamogatas.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onePortion: file(relativePath: { eq: "1adag.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fivePortion: file(relativePath: { eq: "5adag.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tenPortion: file(relativePath: { eq: "10adag.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    xPortion: file(relativePath: { eq: "xadag.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bg: file(relativePath: { eq: "Etesdahosoket-02.png" }) {
      childImageSharp {
        fixed(width: 1920, quality: 100) {
          src
        }
      }
    }
  }
`
