import React,  { useState, useEffect } from "react"
import styled from "styled-components"

import { BACKGROUND_COLOR } from "../../variables"
import Container from "../Container"
import SectionTitle from "../SectionTitle"
import Cards from '../Cards'
import { getPress } from "../../services/api/press"

const PressSection = styled.div`
  background-color: ${BACKGROUND_COLOR};
  padding: 4.5454em 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Press = () => {
  const [press, setPress] = useState([])

  useEffect(() => {
    loadPress()
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const wow = require('wowjs')
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadPress = async () => {
    try {
      const result = await getPress()
      setPress(result.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    press.length > 0 && (
      <PressSection id="media">
        <Container>
          <SectionTitle>Média</SectionTitle>
          <Grid>
            <Cards elements={press} />
          </Grid>
        </Container>
      </PressSection>
    )
  )
}

export default Press
