// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-simple-page-js": () => import("./../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adomanyozas-js": () => import("./../src/pages/adomanyozas.js" /* webpackChunkName: "component---src-pages-adomanyozas-js" */),
  "component---src-pages-felajanlas-js": () => import("./../src/pages/felajanlas.js" /* webpackChunkName: "component---src-pages-felajanlas-js" */),
  "component---src-pages-felajanlasi-kerelmek-js": () => import("./../src/pages/felajanlasi-kerelmek.js" /* webpackChunkName: "component---src-pages-felajanlasi-kerelmek-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koszonjuk-js": () => import("./../src/pages/koszonjuk.js" /* webpackChunkName: "component---src-pages-koszonjuk-js" */)
}

