import { graphql } from "gatsby"
import Img from "gatsby-image"
import qs from "qs"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import DonationForm from "../components/donation/donation-form"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"
import { getBarionEnabled } from "../services/enable-barion"

const DonationPageContainer = styled.div`
  margin: 0 auto;
  padding: 4.5454em 0.681em 8.1818em 0.681em;
  max-width: 760px;
`

const SubHeadingConatainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;

  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`

const SubHeading = styled.p`
  text-align: center;

  @media all and (min-width: 768px) {
    text-align: left;
    margin-left: 1.8181em;
  }
`

const DonationPage = ({ location: { search }, data }) => {
  const [shopEnabled, setShopEnabled] = useState(false);
  const { quantity } = qs.parse(search, { ignoreQueryPrefix: true })
  const { xPortion } = data
  const unit_price = 1000

  useEffect(() => {
    if (typeof window !== "undefined") {
      const wow = require("wowjs")
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  useEffect(() => {
    const { barion } = qs.parse(search, { ignoreQueryPrefix: true })
    setShopEnabled(getBarionEnabled(barion));
  })

  return (
    <Layout>
      <SEO title="Adományozás" />
      <DonationPageContainer>
        <SectionTitle>Adományozás</SectionTitle>
        <SubHeadingConatainer>
          <Img
            fixed={xPortion.childImageSharp.fixed}
            className="wow fadeInLeft"
          />
          <SubHeading className="wow fadeInRight">
            <strong>{quantity} adag</strong> étellel{" "}
            <strong>({unit_price * quantity} Ft)</strong> szeretnék
            hozzájárulni.
          </SubHeading>
        </SubHeadingConatainer>
        {shopEnabled && quantity && (
          <DonationForm quantity={quantity} unit_price={unit_price} />
        )}
      </DonationPageContainer>
    </Layout>
  )
}

export default DonationPage

export const pageQuery = graphql`
  query {
    xPortion: file(relativePath: { eq: "xadag.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
