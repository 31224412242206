/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const wow = require("wowjs")

exports.onInitialClientRender = () => {
  new wow.WOW({ live: false, mobile: false }).init()
};
