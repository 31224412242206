import React, { useEffect, useState } from "react"
import Recaptcha from "react-google-recaptcha"
import styled from "styled-components"
import { useSiteMeta } from "../../hooks/siteMeta"
import { getCities, getOfferingTypes, postOffering } from "../../services/api"
import { SECONDARY_COLOR } from "../../variables"
import CheckboxInput from "../CheckboxInput"
import Container from "../Container"
import FormGroup from "../FormGroup"
import SelectInput from "../SelectInput"
import TextAreaInput from "../TextAreaInput"
import TextInput from "../TextInput"


const OfferingFormContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.8181rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    .grid-span {
      grid-column: span 2;
    }
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: inherit;
  transition: color 0.2s ease-in-out;

  :hover {
    color: ${SECONDARY_COLOR};
  }
`

const ButtonContainer = styled.div`
  grid-column-end: -1;
  display: flex;
  justify-content: flex-end;
`

const SubmitButton = styled.input`
  font-size: 1.0909em;
  font-weight: 700;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  padding: 15px 40px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  max-height: 70px;

  &:hover {
    background-color: #f0ce8a;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const MessageParagraph = styled.p`
  color: green;
`

const CheckboxSmallText = styled.small`
  margin-top: 0.25em;
  display: block;
`

const Error = styled.span`
  color: red;
  font-size: 0.7272em;
`

const initialFormState = {
  name: "",
  phone: "",
  email: "",
  type_id: "",
  description: "",
  city_id: "",
  gdpr: false,
  public_donator: false,
  recaptcha: "",
}

const OfferingForm = () => {
  const [cities, setCities] = useState([])
  const [types, setTypes] = useState([])
  const [values, setValues] = useState(initialFormState)
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})
  const { siteKey } = useSiteMeta()

  useEffect(() => {
    loadCities()
    loadTypes()
  }, [])

  const loadCities = async () => {
    try {
      const citiesResult = await getCities()
      setCities(citiesResult.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  const loadTypes = async () => {
    try {
      const typesResult = await getOfferingTypes()
      setTypes(typesResult.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  const updateFormValue = key => value => {
    setValues({ ...values, [key]: value })
    setErrors({ ...errors, [key]: '' })
  }

  async function onSubmit() {
    if (values.gdpr) {
      setMessage("")

      try {
        setErrors({})
        const result = await postOffering(values)
        setMessage(result.data.message)
        setValues(initialFormState)
        setErrors({})
      } catch (error) {
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data)
        }
        console.error(error)
      }
    } else {
      setErrors({
        ...errors,
        gdpr: "Az adatvédelmi nyilatkozat elfogadása kötelező!",
      })
    }
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit()
      }}
    >
      <OfferingFormContainer>
        <FormGroup labelText="Név">
          <TextInput
            onChange={event => updateFormValue("name")(event.target.value)}
            value={values["name"]}
            error={errors["name"]}
            required
          ></TextInput>
        </FormGroup>
        <FormGroup labelText="Telefon">
          <TextInput
            onChange={event => updateFormValue("phone")(event.target.value)}
            value={values["phone"]}
            error={errors["phone"]}
            type="tel"
            required
          ></TextInput>
        </FormGroup>
        <FormGroup labelText="Email">
          <TextInput
            onChange={event => updateFormValue("email")(event.target.value)}
            value={values["email"]}
            error={errors["email"]}
            type="email"
            required
          ></TextInput>
        </FormGroup>
        <FormGroup labelText="Város">
          <SelectInput
            onChange={event => updateFormValue("city_id")(event.target.value)}
            value={values["city_id"]}
            error={errors["city_id"]}
            required
          >
            <option value={null}></option>{" "}
            {cities &&
              cities.map((city, i) => (
                <option value={city.id} key={i}>
                  {city.name}
                </option>
              ))}
          </SelectInput>
        </FormGroup>
        <FormGroup labelText="Típus">
          <SelectInput
            onChange={event => updateFormValue("type_id")(event.target.value)}
            value={values["type_id"]}
            error={errors["type_id"]}
            required
          >
            <option value={null}></option>{" "}
            {types &&
              types.map((type, i) => (
                <option value={type.id} key={i}>
                  {type.name}
                </option>
              ))}
          </SelectInput>{" "}
        </FormGroup>
        <FormGroup className="grid-span" labelText="Felajánlás">
          <TextAreaInput
            onChange={event =>
              updateFormValue("description")(event.target.value)
            }
            value={values["description"]}
            error={errors["description"]}
            required
          ></TextAreaInput>
        </FormGroup>
        <CheckboxInput
          className="grid-span"
          onChange={event => updateFormValue("gdpr")(event.target.checked)}
          checked={values["gdpr"]}
          error={errors["gdpr"]}
        >
          Elfogadom az{" "}
          <StyledLink
            href="/adatvedelmi-nyilatkozat"
            target="_blank"
            rel="noopener noreferrer"
          >
            adatvédelmi nyilatkozatot
          </StyledLink>
        </CheckboxInput>
        <CheckboxInput
          className="grid-span"
          onChange={event =>
            updateFormValue("public_donator")(event.target.checked)
          }
          checked={values["public_donator"]}
        >
          Publikus adományozó vagyok
          <CheckboxSmallText>
            Ha bepipálod, hogy publikus adományozó vagy, azzal hozzájárulsz, hogy
          megjelenjen a neved a facebook oldalon a támogatoink között egy poszt formájában.
          Kérlek töltsd ki a formot.
          </CheckboxSmallText>
        </CheckboxInput>

        {message && (
          <MessageParagraph className="grid-span">{message}</MessageParagraph>
        )}

        <div>
          <Recaptcha
            sitekey={siteKey}
            onChange={response =>
              response
                ? updateFormValue("recaptcha")(response)
                : setErrors({
                  ...errors,
                  recaptcha: "Nem sikerült a recaptcha validáció!",
                })
            }
          />
          {errors["recaptcha"] && <Error>{errors.recaptcha}</Error>}
        </div>

        <ButtonContainer>
          <SubmitButton onClick={() => { }} type="submit" value="Küldés" />
        </ButtonContainer>
      </OfferingFormContainer>
    </form>
  )
}

export default OfferingForm
